exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-agencies-tsx": () => import("./../../../src/pages/agencies.tsx" /* webpackChunkName: "component---src-pages-agencies-tsx" */),
  "component---src-pages-amazon-accelerator-program-tsx": () => import("./../../../src/pages/amazon-accelerator-program.tsx" /* webpackChunkName: "component---src-pages-amazon-accelerator-program-tsx" */),
  "component---src-pages-amazon-advertising-benchmark-tool-tsx": () => import("./../../../src/pages/amazon-advertising-benchmark-tool.tsx" /* webpackChunkName: "component---src-pages-amazon-advertising-benchmark-tool-tsx" */),
  "component---src-pages-amazon-advertising-software-tsx": () => import("./../../../src/pages/amazon-advertising-software.tsx" /* webpackChunkName: "component---src-pages-amazon-advertising-software-tsx" */),
  "component---src-pages-amazon-amc-audiences-tsx": () => import("./../../../src/pages/amazon-amc-audiences.tsx" /* webpackChunkName: "component---src-pages-amazon-amc-audiences-tsx" */),
  "component---src-pages-amazon-demand-side-platform-managed-service-tsx": () => import("./../../../src/pages/amazon-demand-side-platform-managed-service.tsx" /* webpackChunkName: "component---src-pages-amazon-demand-side-platform-managed-service-tsx" */),
  "component---src-pages-amazon-demand-side-platform-self-service-tsx": () => import("./../../../src/pages/amazon-demand-side-platform-self-service.tsx" /* webpackChunkName: "component---src-pages-amazon-demand-side-platform-self-service-tsx" */),
  "component---src-pages-amazon-incremental-growth-tsx": () => import("./../../../src/pages/amazon-incremental-growth.tsx" /* webpackChunkName: "component---src-pages-amazon-incremental-growth-tsx" */),
  "component---src-pages-amazon-ppc-software-sponsored-ads-management-tool-tsx": () => import("./../../../src/pages/amazon-ppc-software-sponsored-ads-management-tool.tsx" /* webpackChunkName: "component---src-pages-amazon-ppc-software-sponsored-ads-management-tool-tsx" */),
  "component---src-pages-amazon-seo-tool-tsx": () => import("./../../../src/pages/amazon-seo-tool.tsx" /* webpackChunkName: "component---src-pages-amazon-seo-tool-tsx" */),
  "component---src-pages-amc-amazon-marketing-cloud-tsx": () => import("./../../../src/pages/amc-amazon-marketing-cloud.tsx" /* webpackChunkName: "component---src-pages-amc-amazon-marketing-cloud-tsx" */),
  "component---src-pages-blog-search-tsx": () => import("./../../../src/pages/blog/search.tsx" /* webpackChunkName: "component---src-pages-blog-search-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-brand-and-media-tsx": () => import("./../../../src/pages/brand-and-media.tsx" /* webpackChunkName: "component---src-pages-brand-and-media-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-cookie-policy-list-tsx": () => import("./../../../src/pages/cookie-policy/list.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-list-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-ecommerce-business-intelligence-tsx": () => import("./../../../src/pages/ecommerce-business-intelligence.tsx" /* webpackChunkName: "component---src-pages-ecommerce-business-intelligence-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instacart-advertising-software-tsx": () => import("./../../../src/pages/instacart-advertising-software.tsx" /* webpackChunkName: "component---src-pages-instacart-advertising-software-tsx" */),
  "component---src-pages-instagram-tsx": () => import("./../../../src/pages/instagram.tsx" /* webpackChunkName: "component---src-pages-instagram-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-prism-amazon-market-share-tool-tsx": () => import("./../../../src/pages/prism-amazon-market-share-tool.tsx" /* webpackChunkName: "component---src-pages-prism-amazon-market-share-tool-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-sellics-joins-perpetua-tsx": () => import("./../../../src/pages/sellics-joins-perpetua.tsx" /* webpackChunkName: "component---src-pages-sellics-joins-perpetua-tsx" */),
  "component---src-pages-sonar-amazon-keyword-tool-tsx": () => import("./../../../src/pages/sonar-amazon-keyword-tool.tsx" /* webpackChunkName: "component---src-pages-sonar-amazon-keyword-tool-tsx" */),
  "component---src-pages-tiktok-tsx": () => import("./../../../src/pages/tiktok.tsx" /* webpackChunkName: "component---src-pages-tiktok-tsx" */),
  "component---src-pages-video-tsx": () => import("./../../../src/pages/video.tsx" /* webpackChunkName: "component---src-pages-video-tsx" */),
  "component---src-pages-walmart-advertising-software-tsx": () => import("./../../../src/pages/walmart-advertising-software.tsx" /* webpackChunkName: "component---src-pages-walmart-advertising-software-tsx" */),
  "component---src-templates-blog-article-index-tsx": () => import("./../../../src/templates/BlogArticle/index.tsx" /* webpackChunkName: "component---src-templates-blog-article-index-tsx" */),
  "component---src-templates-blog-category-index-tsx": () => import("./../../../src/templates/BlogCategory/index.tsx" /* webpackChunkName: "component---src-templates-blog-category-index-tsx" */),
  "component---src-templates-legal-template-index-tsx": () => import("./../../../src/templates/LegalTemplate/index.tsx" /* webpackChunkName: "component---src-templates-legal-template-index-tsx" */),
  "component---src-templates-resource-template-index-tsx": () => import("./../../../src/templates/ResourceTemplate/index.tsx" /* webpackChunkName: "component---src-templates-resource-template-index-tsx" */),
  "component---src-templates-single-webinar-index-tsx": () => import("./../../../src/templates/SingleWebinar/index.tsx" /* webpackChunkName: "component---src-templates-single-webinar-index-tsx" */),
  "component---src-templates-video-category-index-tsx": () => import("./../../../src/templates/VideoCategory/index.tsx" /* webpackChunkName: "component---src-templates-video-category-index-tsx" */),
  "component---src-templates-webinars-index-tsx": () => import("./../../../src/templates/Webinars/index.tsx" /* webpackChunkName: "component---src-templates-webinars-index-tsx" */)
}

